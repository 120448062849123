export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3894062917928906',
  agentpwd : '3894@8906$',
  apikey : '240029818417d57b99fcca038521dfc4',
  url : 'https://afib.afrikpay.com',
  id : 'afib.afrikpay.com',
  accepturl : 'https://afib.afrikpay.com/payment/web/success',
  cancelurl : 'https://afib.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afib.com/print/",
  storage : 'home/oss'
};
